/******************************************************************************
  Related Posts
*******************************************************************************/
.related-posts {
  .title {
    margin-bottom: $module-rem * 2;
  }
  .post {
    margin-left: auto;
    margin-right: auto;
    max-width: 350px;

    .post-media {
      margin-bottom: $module-rem * 1.5;
    }
    .post-header {
      margin-bottom: 0;
      padding: 0 $module-rem * 1.5 $module-rem * 1.5;

      .post-title {
        @include fontSize(18px);
        margin-bottom: $module-rem;
      }
      .date {
        line-height: 1.3;
      }
    }
  }
  @media (max-width: 767px) {

  }
}