/******************************************************************************
  Masonry Posts
*******************************************************************************/
.masonry-layout {
  display: block;

  .grid-sizer {
    float: left;
    min-height: 0;
  }
  .grid-item {
    float: left;
  }
}