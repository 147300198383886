/******************************************************************************
  Preloader
*******************************************************************************/
.preloader {
	background: rgba(#000,1);
	bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
	z-index: 9999;
	
	.loader {
		animation: scaleout 1.0s infinite $animation;
		background-color: #fff;
		border-radius: 100%;
		height: 40px;
		left: 50%;
		margin: -20px 0 0 -20px;
		position: absolute;
		top: 50%;
		width: 40px;
	}
}
@keyframes scaleout {
  0% {
    transform: scale(0.0);
  } 100% {
    transform: scale(1.0);
    opacity: 0;
  }
}