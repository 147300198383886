// Colors
$main-color: #e17ab4;
$primary:    #428bca;
$success:    #5cb85c;
$info:       #5bc0de;
$warning:    #f0ad4e;
$danger:     #f34d65;
$animation: ease-in-out;

$main-font-family: 'Open Sans', sans-serif;
$main-font-size: 14px;
$main-font-size-767: 13px;
$main-font-weight: 400;
$main-line-height: 2;
$main-line-height-767: 1.5;
$main-text-color: #999;

$second-font-family: 'Josefin Sans', sans-serif;

$module: 14px;
$module-rem: ($module / $main-font-size) * 1rem;

$max543 : only screen and (max-width: 543px);
$min544 : only screen and (min-width: 544px);
$max767 : only screen and (max-width: 767px);
$min768 : only screen and (min-width: 768px);
$max991 : only screen and (max-width: 991px);
$min992 : only screen and (min-width: 992px);
$max1199 : only screen and (max-width: 1199px);
$min1200 : only screen and (min-width: 1200px);

// Headers
$h1-h6-color: #000;
$h1-h6-font-weight: 600;
$h1-h6-font-family: $second-font-family;
$h1-h6-line-height: 1.3;
$h1-h6-letter-spacing: .025em;
$h1-h6-margin-bottom: #{$h1-h6-line-height / 2}em;

$h1-font-size: 66.59px;
$h2-font-size: 50px;
$h3-font-size: 37.64px;
$h4-font-size: 28.3px;
$h5-font-size: 21.28px;
$h6-font-size: 16px;

// Header
$header-height: $module * 7;
$header-height-767: $module * 4;
$divider-header-height: $module * 5;
$header-height-767: $module * 4;

@function calculateRem($size) {
  $remSize: $size / $main-font-size;
  @return #{$remSize}rem;
}
@mixin fontSize($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

//Fa icons
%fa {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}