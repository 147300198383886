/******************************************************************************
  Page
*******************************************************************************/
.page {
  .page-content {
    padding: $module-rem * 3;
    @media #{$max767} {
      padding: $module-rem * 1.5;
    }
  }
}