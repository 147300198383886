/******************************************************************************
  Widgets
*******************************************************************************/
.widget {
  padding: $module-rem * 1.5;

  &:last-child {
    margin-bottom: 0;
  }
  .widget-title {
    color: #000;
    @include fontSize(21px);
    margin-bottom: $module-rem * 3;
    padding-bottom: 10px;
    position: relative;
    text-align: center;

    span {
      background: #fff;
      padding: 0 10px;
      position: relative;
    }
    &:before {
      border: 1px solid #ccc;
      bottom: 0;
      content: '';
      height: 24px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  //About Me
  &.about-me-widget {
    text-align: center;

    .about-img {
      margin: 0 0 $module-rem * 5;

      img {
        border-radius: 50%;
      }
    }
    a {
      font-style: italic;
    }
  }
  //Social
  &.social-widget {
    .social {
      text-align: center;
    }
  }
  //Menu
  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px dashed #ccc;
      color: #9e9e9e;
      padding: 10px $module * 2;
      position: relative;

      &:last-child {
        border-bottom: 0;
      }
      a {
        color: #000;

        &:hover {
          color: $main-color;
        }
      }
      &.active {
        &:before {
          border: 1px solid $main-color;
          border-radius: 50%;
          content: '';
          height: 6px;
          left: 1px;
          margin-top: -3px;
          position: absolute;
          top: 50%;
          width: 6px;
        }
        a {
          color: $main-color;
        }
      }
    }
  }
  //Latest Posts
  &.latest-posts-widget {
    ul {
      li {
        overflow: hidden;
        padding: 15px 0;

        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
        .post-img {
          display: block;
          height: 68px;
          float: left;
          width: 68px;
          @media (max-width: 1199px) and (min-width: 992px) {
            height: 52px;
            width: 52px;
          }
        }
        .post-content {
          overflow: hidden;
          padding-left: 30px;

          .post-title {
            @include fontSize(16px);
            margin-bottom: .5em;

            a {
              color: #000;

              &:hover {
                color: $main-color;
              }
            }
          }
          .date {
            @include fontSize(12px);
          }
        }
      }
    }
  }
  //Banner
  &.banner-widget {
    overflow: hidden;
    padding: 0;
    position: relative;

    .banner-img {
      display: block;
    }
    .banner-link {
      cursor: pointer;
      display: block;
      height: 100%;
      left: 0;
      padding: $module * 2;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;

      .cell-vertical-wrapper {
        background: rgba(#000,.6);
        display: table;
        height: 100%;
        transition: background .2s $animation;
        width: 100%;

        .cell-middle {
          color: #fff;
          display: table-cell;
          height: 100%;
          vertical-align: middle;

          .title {
            color: #fff;
            &:only-child {
              margin-bottom: 0;
            }
          }
        }
      }
      &:hover {
        .cell-vertical-wrapper {
          background: rgba($main-color,.6);
        }
      }
    }
  }
  //Gallery
  &.gallery-widget {
    ul {
      margin: -3px;
      overflow: hidden;

      li {
        float: left;
        padding: 3px;
        width: 33.33333333%;

        a {
          display: block;
          height: auto;
          width: 100%;
        }
      }
    }
  }
  //Subscribe
  .subscribe {
    .form-group {
      //margin-bottom: 2.25rem;
      position: relative;

      .form-message {
        bottom: 100%;
        font-size: .9rem;
        margin-bottom: .1rem;
        position: absolute;
      }
    }
  }

}
.gallery-item {
  position: relative;

  &:before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition:
      background .2s $animation,
      bottom .2s $animation,
      left .2s $animation,
      right .2s $animation,
      top .2s $animation;
  }
  &:hover {
    &:before {
      background: rgba(#000,.6);
      bottom: $module/2;
      left: $module/2;
      right: $module/2;
      top: $module/2;
    }
  }
}
.tagcloud {
  font-size: 0;
  margin: -4px;

  a {
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    color: #8f8f8f;
    display: inline-block;
    @include fontSize(12px);
    margin: 4px;
    padding: $module-rem / 2 $module-rem * 1.5;
    transition:
      background .2s $animation,
      border .2s $animation,
      color .2s $animation;
    text-transform: uppercase;
    vertical-align: top;

    &:hover {
      background: transparent;
      border-color: $main-color;
      color: $main-color;
    }
  }
}
