/******************************************************************************
  List Posts
*******************************************************************************/
.list-layout {
  .post {
    @media #{$max543} {
      margin-left: auto;
      margin-right: auto;
      max-width: 350px;
    }
    .post-entry {
      padding-top: $module-rem * 3;
      padding-bottom: $module-rem * 3;
      @media #{$max767} {
        padding-left: 0;
      }
      @media #{$max543} {
        padding-top: $module-rem * 2;
        padding-bottom: $module-rem * 2;
        padding-left: $module-rem * 1.5;
      }
    }
    .post-media {
      margin: 0;

      a {
        display: block;
        height: 100%;
        width: 100%;

        img {
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
          width: 100%;
        }
      }
    }
    .post-header {
      margin-bottom: $module-rem * 3;
      padding-left: 0;
      padding-top: 0 !important;
      text-align: left;
      @media #{$max1199} {
        margin-bottom: $module-rem * 2;
      }
      @media #{$max543} {
        padding-left: $module-rem * 1.5;
      }
      .tags {
        @media #{$max1199} {
          margin-bottom: $module-rem;
        }
      }
      .post-title {
        @media #{$max1199} {
          margin-bottom: $module-rem;
        }
      }
    }
    .post-content {
      margin-bottom: 0;
      overflow: hidden;
      padding-left: 0;
      @media #{$max543} {
        max-height: inherit;
        padding-left: $module-rem * 1.5;
      }
    }
    .post-footer {
      .post-author {
        padding-left: 0;
      }
    }
  }
}