/******************************************************************************
  Pagination
*******************************************************************************/
.pager {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;

  li {
    display: block;

    > a,
    > span {
      background: none;
      border: 0;
      color: #000;
      @include fontSize(16px);
      padding: 0;

      &:hover,
      &:focus {
        background: none;
        color: $main-color;

        .arrow-left {
          &:before {
            background: $main-color;
          }
          &:after {
            border-right-color: $main-color;
          }
        }
        .arrow-right {
          &:before {
            background: $main-color;
          }
          &:after {
            border-left-color: $main-color;
          }
        }
      }
    }
  }
}
.pagination {
  border-radius: 0;
  display: block;
  margin: 0;

  .page-numbers {
    line-height: 1;
    list-style-type: none;
    margin: 0;
    padding: 0;
    vertical-align: top;

    li {
      display: inline-block;
      margin: 0 2px;
      vertical-align: top;
      
      a,
      span {
        background: #e5e5e5;
        border: 1px solid #e5e5e5;
        color: #666;
        display: block;
        height: 24px;
        line-height: 1;
        min-width: 24px;
        padding: 4px 3px;
        text-align: center;
        transition:
          background .2s $animation,
          border .2s $animation,
          color .2s $animation;
        vertical-align: baseline;
      }
      a:hover {
        background: transparent;
        border-color: $main-color;
        color: $main-color;
      }
      .current {
        background: $main-color;
        border-color: $main-color;
        color: #fff;
      }
    }
  }
}