/******************************************************************************
  Page 404
*******************************************************************************/
#main.page-404 {
  align-items: center;
  display: flex;
  padding: $module-rem * 4 0;
  @media #{$max767} {
    padding: $module-rem * 2 0;
  }

  .page-content {
    h3 {
      margin-bottom: $module-rem * 4;
      @media #{$max767} {
        margin-bottom: $module-rem * 3;
      }
    }
  }
}