/*
Theme Name: Margery
Theme URI: http://margery.next-item.com/blog
Author: Next Item
Author URI: next-item.com
Description: Personal Blog Template
Version: 1.0.1
License: ThemeForest Regular & Extended License
License URI: http://themeforest.net/licenses/regular_extended

[Table of contents]
1. Typography
2. Structure
3. Helper Classes
4. Preloader
5. Header
6. Slider
7. Banners
8. Footer
9. Animation
10. Elements
11. Post
12. Sidebar
13. Widgets
14. Page
*/

@charset "UTF-8";

//Variables
@import "variables";
@import "fonts";

@import "typography";
@import "structure";
@import "helper-classes";
@import "preloader";

@import "header/_divided.scss";
@import "header/_dynamic.scss";
@import "header/_fixed.scss";
@import "header/_header.scss";
@import "header/_transparent.scss";
@import "slider";
@import "banners";
@import "footer";
@import "animation";

//Elements
@import "elements/_buttons.scss";
@import "elements/_forms.scss";
@import "elements/_other.scss";
@import "elements/_pagination.scss";
@import "elements/_social.scss";
@import "elements/_tabs.scss";

//Blog
@import "post/_grid-posts.scss";
@import "post/_list-posts.scss";
@import "post/_masonry-posts.scss";
@import "post/_modern-posts.scss";
@import "post/_post-comments.scss";
@import "post/_post.scss";
@import "post/_related-posts.scss";
@import "post/_social-posts.scss";
@import "post/_timeline-posts.scss";

//Sidebar
@import "sidebar";
@import "widgets";

//Pages
@import "page/_404.scss";
@import "page/_page.scss";