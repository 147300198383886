@charset "UTF-8";
/*
Theme Name: Margery
Theme URI: http://margery.next-item.com/blog
Author: Next Item
Author URI: next-item.com
Description: Personal Blog Template
Version: 1.0.1
License: ThemeForest Regular & Extended License
License URI: http://themeforest.net/licenses/regular_extended

[Table of contents]
1. Typography
2. Structure
3. Helper Classes
4. Preloader
5. Header
6. Slider
7. Banners
8. Footer
9. Animation
10. Elements
11. Post
12. Sidebar
13. Widgets
14. Page
*/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic,600,600italic,300,300italic|Josefin+Sans:400,300,300italic,400italic,600,600italic,700,700italic);
/******************************************************************************
  Typography
*******************************************************************************/
html {
  font-size: 14px;
  height: 100%; }
  @media (max-width: 767px) {
    html {
      font-size: 13px; } }

body {
  background: #f9f9f9;
  color: #999;
  font-size: 14px;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  height: 100%;
  line-height: 2;
  min-width: 300px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word; }
  @media (max-width: 767px) {
    body {
      line-height: 1.5; } }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: #000;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.025em;
  margin: 0 0 0.65em; }

h1, .h1 {
  font-size: 66.59px;
  font-size: 4.75643rem; }
  @media (max-width: 767px) {
    h1, .h1 {
      font-size: 44.39333px;
      font-size: 3.17095rem; } }

h2, .h2 {
  font-size: 50px;
  font-size: 3.57143rem; }
  @media (max-width: 767px) {
    h2, .h2 {
      font-size: 35.71429px;
      font-size: 2.55102rem; } }

h3, .h3 {
  font-size: 37.64px;
  font-size: 2.68857rem; }
  @media (max-width: 767px) {
    h3, .h3 {
      font-size: 28.95385px;
      font-size: 2.06813rem; } }

h4, .h4 {
  font-size: 28.3px;
  font-size: 2.02143rem; }
  @media (max-width: 767px) {
    h4, .h4 {
      font-size: 23.58333px;
      font-size: 1.68452rem; } }

h5, .h5 {
  font-size: 21.28px;
  font-size: 1.52rem; }
  @media (max-width: 767px) {
    h5, .h5 {
      font-size: 19.34545px;
      font-size: 1.38182rem; } }

h6, .h6 {
  font-size: 16px;
  font-size: 1.14286rem; }

p,
form {
  margin: 0 0 1rem; }
  @media (max-width: 767px) {
    p,
    form {
      margin: 0 0 0.75rem; } }

p:last-child {
  margin-bottom: 0; }

a {
  color: #e17ab4;
  text-decoration: none;
  transition: color 0.2s ease-in-out; }
  a:hover, a:focus {
    color: rgba(225, 122, 180, 0.7);
    outline: none;
    text-decoration: none; }

ul,
ol {
  margin: 0 0 1rem;
  padding: 0 0 0 1rem; }
  @media (max-width: 767px) {
    ul,
    ol {
      margin: 0 0 0.75rem; } }
  ul .no-markers,
  ol .no-markers {
    letter-spacing: normal;
    padding-left: 0; }
    ul .no-markers > li,
    ol .no-markers > li {
      list-style-type: none; }

img {
  height: auto;
  max-width: 100%; }

hr {
  border-color: rgba(0, 0, 0, 0.15);
  margin-top: 2rem;
  margin-bottom: 2rem; }
  @media (max-width: 767px) {
    hr {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem; } }

.blockquote {
  border: 0;
  font-size: 18px;
  font-size: 1.28571rem;
  font-style: italic;
  margin-bottom: 1rem;
  padding: 0;
  quotes: "“" "”"; }
  @media (max-width: 767px) {
    .blockquote {
      margin-bottom: 0.75rem; } }
  .blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    line-height: .1em;
    margin-right: .25em;
    vertical-align: -.35em; }

.table {
  margin-bottom: 1rem; }
  @media (max-width: 767px) {
    .table {
      margin-bottom: 0.75rem; } }
  .table thead td,
  .table th {
    color: #000; }
  .table.table-bordered,
  .table.table-bordered td,
  .table.table-bordered th {
    border-color: #ccc; }
  .table:last-child {
    margin-bottom: 0; }

.figure {
  margin-bottom: 1rem; }
  @media (max-width: 767px) {
    .figure {
      margin-bottom: 0.75rem; } }
  .figure .figure-caption {
    color: #ccc;
    font-size: 12px;
    font-size: 0.85714rem;
    line-height: 1.5;
    text-align: center; }
  .figure.pull-left, .figure.pull-xs-left {
    margin-right: 30px; }
  @media (min-width: 544px) {
    .figure.pull-sm-left {
      margin-right: 30px; } }
  @media (min-width: 768px) {
    .figure.pull-md-left {
      margin-right: 30px; } }
  @media (min-width: 992px) {
    .figure.pull-lg-left {
      margin-right: 30px; } }
  @media (min-width: 1200px) {
    .figure.pull-xl-left {
      margin-right: 30px; } }
  .figure.pull-right, .figure.pull-xs-right {
    margin-left: 30px; }
  @media (min-width: 544px) {
    .figure.pull-sm-right {
      margin-left: 30px; } }
  @media (min-width: 768px) {
    .figure.pull-md-right {
      margin-left: 30px; } }
  @media (min-width: 992px) {
    .figure.pull-lg-right {
      margin-left: 30px; } }
  @media (min-width: 1200px) {
    .figure.pull-xl-right {
      margin-left: 30px; } }

/******************************************************************************
  Structure
*******************************************************************************/
.page-box {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
  padding-top: 98px; }
  @media (max-width: 767px) {
    .page-box {
      padding-top: 56px; } }

#main {
  flex: 1 0 auto;
  padding: 0 0 4rem; }
  @media (max-width: 767px) {
    #main {
      padding: 0 0 2rem; } }
  #main.top-indent {
    padding-top: 4rem; }
    @media (max-width: 767px) {
      #main.top-indent {
        padding-top: 2rem; } }
  #main .page-header {
    color: #000;
    padding: 6rem 1.5rem;
    position: relative;
    text-align: center; }
    @media (max-width: 767px) {
      #main .page-header {
        padding-top: 3rem;
        padding-bottom: 3rem; } }
    #main .page-header.bg-page-header {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      color: #fff;
      padding-top: 8rem;
      padding-bottom: 8rem;
      margin-bottom: 4rem; }
      @media (max-width: 767px) {
        #main .page-header.bg-page-header {
          margin-bottom: 2rem;
          padding-top: 4rem;
          padding-bottom: 4rem; } }
      #main .page-header.bg-page-header:before {
        background: rgba(0, 0, 0, 0.7);
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0; }
    #main .page-header h1, #main .page-header .h1, #main .page-header h2, #main .page-header .h2, #main .page-header h3, #main .page-header .h3, #main .page-header h4, #main .page-header .h4, #main .page-header h5, #main .page-header .h5, #main .page-header h6, #main .page-header .h6 {
      color: inherit;
      margin: 0;
      position: relative; }
    #main .page-header h1, #main .page-header .h1 {
      font-size: 79.908px;
      font-size: 5.70771rem; }
      @media only screen and (max-width: 767px) {
        #main .page-header h1, #main .page-header .h1 {
          font-size: 47.56429px;
          font-size: 3.39745rem; } }
  #main.left-sidebar .sidebar {
    padding-left: 1.07145rem;
    padding-right: 3rem; }

/******************************************************************************
  Helper Classes
*******************************************************************************/
.accent-color {
  color: #e17ab4 !important; }

@media (max-width: 543px) {
  .margin-xs {
    margin-bottom: 2rem; }
  .margin-xs-1 {
    margin-bottom: 1rem; } }

@media (max-width: 767px) {
  .margin-sm {
    margin-bottom: 2rem; }
  .margin-sm-1 {
    margin-bottom: 1rem; } }

@media (max-width: 991px) {
  .margin-md {
    margin-bottom: 2rem; }
  .margin-md-1 {
    margin-bottom: 1rem; } }

@media (max-width: 1199px) {
  .margin-lg {
    margin-bottom: 2rem; }
  .margin-lg-1 {
    margin-bottom: 1rem; } }

.margin {
  margin-bottom: 4rem; }
  @media (max-width: 767px) {
    .margin {
      margin-bottom: 1.5rem; } }

.padding {
  padding: 3rem; }
  @media (max-width: 767px) {
    .padding {
      padding: 1.5rem; } }

.buttons-list .btn {
  margin: 5px; }

.bg {
  background: #fff; }

.bg-overlay {
  background: rgba(0, 0, 0, 0.05); }

.accent-color {
  color: #e17ab4; }

.all-caps,
.all-caps * {
  text-transform: uppercase; }

.nowrap {
  white-space: nowrap; }

.date {
  color: #999;
  font-size: 13px;
  font-size: 0.92857rem;
  font-family: "Josefin Sans", sans-serif;
  letter-spacing: .025em; }

.twitter-bg {
  background: #55acee; }

.facebook-bg {
  background: #3B5998; }

.google-plus-bg {
  background: #dd4b39; }

.pinterest-bg {
  background: #C92228; }

.section {
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media (max-width: 767px) {
    .section {
      padding-top: 1rem;
      padding-bottom: 1rem; } }

.music-iframe {
  padding-bottom: 200px; }

.required,
.red-text {
  color: #fb631e; }

.green-text {
  color: #5D9110; }

/******************************************************************************
  Preloader
*******************************************************************************/
.preloader {
  background: black;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999; }
  .preloader .loader {
    animation: scaleout 1s infinite ease-in-out;
    background-color: #fff;
    border-radius: 100%;
    height: 40px;
    left: 50%;
    margin: -20px 0 0 -20px;
    position: absolute;
    top: 50%;
    width: 40px; }

@keyframes scaleout {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1);
    opacity: 0; } }

/******************************************************************************
  Divided Header
*******************************************************************************/
.divided-header .page-box {
  padding-top: 70px; }

.divided-header .site-header {
  height: 70px;
  max-height: 70px; }
  .divided-header .site-header .container > .row,
  .divided-header .site-header .container-fluid > .row {
    justify-content: space-between; }
    .divided-header .site-header .container > .row .col,
    .divided-header .site-header .container-fluid > .row .col {
      height: 100%;
      width: auto; }
      .divided-header .site-header .container > .row .col .logo,
      .divided-header .site-header .container-fluid > .row .col .logo {
        position: relative; }
        @media (min-width: 544px) {
          .divided-header .site-header .container > .row .col .logo:before,
          .divided-header .site-header .container-fluid > .row .col .logo:before {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            content: '';
            display: block;
            height: 100%;
            left: 100%;
            margin-left: 1.5rem;
            pointer-events: none;
            position: absolute;
            width: 0; } }
      @media (max-width: 543px) {
        .divided-header .site-header .container > .row .col.menu-col,
        .divided-header .site-header .container-fluid > .row .col.menu-col {
          padding-right: 0; } }
      .divided-header .site-header .container > .row .col.menu-col .menu,
      .divided-header .site-header .container-fluid > .row .col.menu-col .menu {
        margin: 0 auto; }
        .divided-header .site-header .container > .row .col.menu-col .menu .menu-item-has-children > .sub-menu-wrap,
        .divided-header .site-header .container-fluid > .row .col.menu-col .menu .menu-item-has-children > .sub-menu-wrap {
          padding-top: 24px; }
      .divided-header .site-header .container > .row .col .header-actions,
      .divided-header .site-header .container-fluid > .row .col .header-actions {
        align-items: center;
        display: flex;
        height: 100%; }
        @media (min-width: 544px) {
          .divided-header .site-header .container > .row .col .header-actions:before,
          .divided-header .site-header .container-fluid > .row .col .header-actions:before {
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            content: '';
            display: block;
            height: 100%;
            margin-left: -1.5rem;
            pointer-events: none;
            position: absolute;
            width: 0; } }
        @media (min-width: 544px) {
          .divided-header .site-header .container > .row .col .header-actions .action-box:first-child,
          .divided-header .site-header .container-fluid > .row .col .header-actions .action-box:first-child {
            margin-left: 0; } }
      @media (max-width: 543px) {
        .divided-header .site-header .container > .row .col:first-child,
        .divided-header .site-header .container-fluid > .row .col:first-child {
          flex: auto; }
        .divided-header .site-header .container > .row .col:last-child,
        .divided-header .site-header .container-fluid > .row .col:last-child {
          max-width: initial;
          padding-left: 0; }
        .divided-header .site-header .container > .row .col.menu-col,
        .divided-header .site-header .container-fluid > .row .col.menu-col {
          max-width: initial; } }

/******************************************************************************
  Dynamic Header
*******************************************************************************/
@media (min-width: 768px) {
  .dynamic-header .site-header {
    position: fixed;
    z-index: 999; }
    .dynamic-header .site-header.small-height {
      height: 42px;
      max-height: 42px; }
      .dynamic-header .site-header.small-height .menu .sub-menu-wrap {
        padding-top: 13px; } }

/******************************************************************************
  Fixed Header
*******************************************************************************/
@media (min-width: 768px) {
  .fixed-header .site-header {
    position: fixed;
    z-index: 999; } }

/******************************************************************************
  Header
*******************************************************************************/
.site-header {
  background: rgba(255, 255, 255, 0.95);
  backface-visibility: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
  height: 98px;
  max-height: 98px;
  position: absolute;
  top: 0;
  transition: background 0.2s ease-in-out, height 0.2s linear, max-height 0.2s linear;
  width: 100%;
  z-index: 2; }
  @media (max-width: 767px) {
    .site-header {
      height: 56px;
      max-height: 56px; } }
  .site-header .container,
  .site-header .container-fluid {
    height: 100%;
    max-height: inherit; }
    .site-header .container > .row,
    .site-header .container-fluid > .row {
      align-items: center;
      height: 100%;
      max-height: inherit;
      position: relative; }
      .site-header .container > .row .col,
      .site-header .container-fluid > .row .col {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        line-height: 1;
        max-height: inherit;
        position: static; }
        .site-header .container > .row .col.right-align,
        .site-header .container-fluid > .row .col.right-align {
          justify-content: flex-end; }
        .site-header .container > .row .col > *:not(.active),
        .site-header .container-fluid > .row .col > *:not(.active) {
          transition: opacity 0.3s ease-in-out; }
  .site-header .logo {
    border: 0 none;
    display: inline-block;
    max-height: inherit;
    transition: height 0.2s ease-in-out;
    vertical-align: top; }
    .site-header .logo img {
      max-height: inherit;
      padding: 8px 0;
      width: auto; }
  .site-header .menu .menu-btn {
    display: none;
    margin: 0 3px;
    padding: 1px 5px; }
    .site-header .menu .menu-btn span {
      background: #000;
      display: block;
      height: 2px;
      margin-bottom: 6px;
      transition: background 0.2s ease-in-out;
      width: 22px; }
      .site-header .menu .menu-btn span:last-child {
        margin-bottom: 0; }
    .site-header .menu .menu-btn:hover span {
      background: #e17ab4; }
  .site-header .menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  .site-header .menu .menu-list {
    line-height: 1.5; }
    .site-header .menu .menu-list > li {
      display: inline-block;
      padding: 0 5px;
      vertical-align: top; }
      .site-header .menu .menu-list > li > a {
        color: #000;
        display: block;
        font-family: "Josefin Sans", sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: .025em;
        padding: 3px 10px 4px; }
        @media (max-width: 1199px) {
          .site-header .menu .menu-list > li > a {
            padding-left: 5px;
            padding-right: 5px; } }
      .site-header .menu .menu-list > li.menu-item-has-children > a:after {
        border: 1px solid #999;
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 6px;
        margin: 0 0 0 8px;
        vertical-align: middle;
        width: 6px; }
      body:not(.touch-device) .site-header .menu .menu-list > li:hover > a, body:not(.touch-device) .site-header .menu .menu-list > li.active > a {
        color: #e17ab4; }
  .site-header .menu .sub-menu-wrap {
    left: 0;
    opacity: 0;
    padding-top: 38px;
    position: absolute;
    top: 100%;
    transition: 0.1s opacity 0s ease-in-out, 0.2s padding 0s ease-in-out, 0.3s visibility 0s ease-in-out;
    visibility: hidden;
    z-index: 999; }
    .site-header .menu .sub-menu-wrap.reverted {
      left: auto;
      right: 0; }
  .site-header .menu .sub-menu {
    background: #fff;
    border-top: 2px solid #e17ab4;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 10px 0;
    text-align: left;
    width: 200px; }
    .site-header .menu .sub-menu li a {
      color: #999;
      display: block;
      font-weight: 400;
      padding: 5px 15px; }
    body:not(.touch-device) .site-header .menu .sub-menu li:hover > a, body:not(.touch-device) .site-header .menu .sub-menu li.active > a {
      color: #e17ab4; }
    .site-header .menu .sub-menu .menu-item-has-children > a:before {
      color: rgba(0, 0, 0, 0.54);
      content: "\f105";
      display: block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 16px;
      line-height: 1;
      margin-top: -9px;
      position: absolute;
      right: 5px;
      top: 50%;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .site-header .menu .sub-menu .menu-item-has-children .sub-menu-wrap {
      left: 100%;
      margin-top: -12px;
      padding-top: 0 !important;
      top: 0; }
      .site-header .menu .sub-menu .menu-item-has-children .sub-menu-wrap.reverted {
        left: auto;
        right: 100%; }
    .site-header .menu .sub-menu .menu-item-has-children:hover > .sub-menu-wrap {
      opacity: 1;
      transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out;
      visibility: visible; }
  .site-header .menu .menu-item-has-children {
    position: relative; }
    .site-header .menu .menu-item-has-children:hover > .sub-menu-wrap {
      opacity: 1;
      transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out;
      visibility: visible; }
  .site-header .menu.collapsed .menu-btn {
    display: block; }
  .site-header .menu.collapsed .menu-list-wrap {
    background: rgba(0, 0, 0, 0.87);
    display: none;
    height: 100%;
    left: 0;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 998; }
    .site-header .menu.collapsed .menu-list-wrap .menu-list {
      background: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      height: 100%;
      left: 0;
      margin: 0;
      max-height: 100%;
      overflow: auto;
      padding: 0;
      position: fixed;
      text-align: left;
      top: 0;
      transition: transform 0.2s ease-in-out;
      transform: translate3d(-300px, 0, 0);
      width: 300px; }
      .site-header .menu.collapsed .menu-list-wrap .menu-list li {
        display: block;
        margin: 0;
        padding: 0; }
        .site-header .menu.collapsed .menu-list-wrap .menu-list li a {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 15px 10px 50px;
          position: relative; }
        .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > a .open-sub {
          cursor: pointer;
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 45px; }
          .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > a .open-sub:before, .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > a .open-sub:after {
            background: #e17ab4;
            content: "";
            display: block;
            height: 16px;
            margin: -8px 0 0 23px;
            position: absolute;
            top: 50%;
            width: 2px; }
          .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > a .open-sub:before {
            height: 2px;
            margin: -1px 0 0 16px;
            width: 16px; }
        .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu-wrap,
        .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu {
          display: none;
          margin: 0;
          padding: 0 !important;
          position: relative;
          top: 0; }
          .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu-wrap .sub-menu,
          .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu .sub-menu {
            background: none;
            border: 0;
            box-shadow: none;
            padding: 0;
            width: 100%; }
            .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu-wrap .sub-menu li a,
            .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu .sub-menu li a {
              padding-left: 60px; }
            .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu-wrap .sub-menu .sub-menu-wrap,
            .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu .sub-menu .sub-menu-wrap {
              left: 0;
              right: 0; }
              .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu-wrap .sub-menu .sub-menu-wrap li a,
              .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu .sub-menu .sub-menu-wrap li a {
                padding: 8px 15px 8px 70px; }
            .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu-wrap .sub-menu .menu-item-has-children > a:before,
            .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children > .sub-menu .sub-menu .menu-item-has-children > a:before {
              display: none; }
        .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children.open > a {
          color: #e17ab4; }
          .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children.open > a .open-sub:before {
            background: #000; }
          .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children.open > a .open-sub:after {
            display: none; }
        .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children.open > .sub-menu-wrap,
        .site-header .menu.collapsed .menu-list-wrap .menu-list li.menu-item-has-children.open > .sub-menu {
          display: block;
          opacity: 1;
          visibility: visible; }
  .site-header .menu.collapsed.open-menu .menu-list-wrap .menu-list {
    transform: translate3d(0, 0, 0); }
  .site-header .action-box {
    margin: 0 8px;
    max-height: inherit; }
    .site-header .action-box:last-child {
      margin-right: 0; }
    .site-header .action-box .action-content {
      display: table;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: 0.1s opacity 0s ease-in-out, 0.3s visibility 0s ease-in-out;
      visibility: hidden;
      width: 100%; }
      .site-header .action-box .action-content .action-content-wrap {
        display: table-cell;
        height: 100%;
        max-height: inherit;
        padding: 0 1.5rem;
        vertical-align: middle; }
    .site-header .action-box.dropdown {
      position: static; }
      .site-header .action-box.dropdown .action-content {
        display: block;
        height: auto;
        left: auto;
        padding-top: 0;
        right: 15px;
        top: 100%;
        width: 280px; }
        .site-header .action-box.dropdown .action-content .action-content-wrap {
          background: #fff;
          border-top: 2px solid #e17ab4;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          display: block;
          padding: 0;
          text-align: left;
          width: 100%; }
          .site-header .action-box.dropdown .action-content .action-content-wrap .cart-title {
            border-bottom: 1px solid #ccc;
            padding: .5rem 1rem; }
            .site-header .action-box.dropdown .action-content .action-content-wrap .cart-title .h5 {
              margin-bottom: 0; }
          .site-header .action-box.dropdown .action-content .action-content-wrap .cart-list {
            list-style-type: none;
            margin: 0;
            padding: 0; }
            .site-header .action-box.dropdown .action-content .action-content-wrap .cart-list .mini-cart-item {
              padding: 1rem;
              position: relative; }
              .site-header .action-box.dropdown .action-content .action-content-wrap .cart-list .mini-cart-item .remove {
                color: #f34d65;
                font-size: 16px;
                position: absolute;
                right: 1rem; }
                .site-header .action-box.dropdown .action-content .action-content-wrap .cart-list .mini-cart-item .remove:hover {
                  color: #000; }
              .site-header .action-box.dropdown .action-content .action-content-wrap .cart-list .mini-cart-item .product-thumbnail {
                display: block;
                float: left;
                margin-right: 1rem;
                position: relative;
                width: 50px; }
                .site-header .action-box.dropdown .action-content .action-content-wrap .cart-list .mini-cart-item .product-thumbnail:before {
                  bottom: 0;
                  content: '';
                  left: 0;
                  position: absolute;
                  right: 0;
                  top: 0;
                  transition: background 0.2s ease-in-out, transform 0.2s ease-in-out; }
                .site-header .action-box.dropdown .action-content .action-content-wrap .cart-list .mini-cart-item .product-thumbnail:hover:before {
                  background: rgba(0, 0, 0, 0.6);
                  transform: scale(0.9); }
              .site-header .action-box.dropdown .action-content .action-content-wrap .cart-list .mini-cart-item .product-title {
                font-size: 16px;
                font-size: 1.14286rem;
                padding-right: 20px; }
                .site-header .action-box.dropdown .action-content .action-content-wrap .cart-list .mini-cart-item .product-title a {
                  color: #000; }
                  .site-header .action-box.dropdown .action-content .action-content-wrap .cart-list .mini-cart-item .product-title a:hover {
                    color: #e17ab4; }
              .site-header .action-box.dropdown .action-content .action-content-wrap .cart-list .mini-cart-item .quantity .price {
                color: #999;
                font-size: 1rem; }
          .site-header .action-box.dropdown .action-content .action-content-wrap .total {
            margin: 0;
            padding: .75rem 1rem 0; }
          .site-header .action-box.dropdown .action-content .action-content-wrap .buttons {
            display: flex;
            padding: 1rem; }
            .site-header .action-box.dropdown .action-content .action-content-wrap .buttons .btn {
              padding-left: 20px;
              padding-right: 20px;
              width: 50%; }
              .site-header .action-box.dropdown .action-content .action-content-wrap .buttons .btn:first-child {
                margin-right: -1px;
                position: relative; }
                .site-header .action-box.dropdown .action-content .action-content-wrap .buttons .btn:first-child:before {
                  border-right: 1px solid rgba(255, 255, 255, 0.2);
                  content: '';
                  display: block;
                  height: 100%;
                  position: absolute;
                  right: 0;
                  top: 0; }
    .site-header .action-box.cart-action .header-btn {
      height: 24px;
      width: 24px; }
      .site-header .action-box.cart-action .header-btn .count {
        background: #e17ab4;
        border-radius: 7px;
        color: #fff;
        font-size: 10px;
        height: 14px;
        left: 100%;
        line-height: 1;
        margin: -3px 0 0 -9px;
        min-width: 14px;
        padding: 1px 4px;
        position: absolute;
        text-align: center;
        top: 0; }
    .site-header .action-box.active .action-content {
      opacity: 1;
      transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out;
      visibility: visible;
      z-index: 999; }
  .site-header .header-btn {
    border: 0 none;
    display: inline-block;
    height: 20px;
    margin-top: 0;
    position: relative;
    text-decoration: none;
    transition: opacity 0.3s ease-in-out;
    vertical-align: middle;
    width: 20px; }
    .site-header .header-btn svg {
      fill: #999;
      transition: fill 0.2s ease-in-out;
      width: 100%; }
    body:not(.touch-device) .site-header .header-btn:hover svg {
      fill: #e17ab4; }
  .site-header .close {
    background: none;
    border-radius: 0;
    box-shadow: none;
    display: block;
    height: 41px;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    width: 41px; }
    .site-header .close:before, .site-header .close:after {
      background: rgba(0, 0, 0, 0.54);
      content: '';
      display: block;
      position: absolute;
      transition: background 0.2s ease-in-out;
      transform: rotate(45deg);
      transform-origin: 50% 50%; }
    .site-header .close:after {
      height: 21px;
      right: 10px;
      top: 10px;
      width: 1px; }
    .site-header .close:before {
      height: 1px;
      right: 0;
      top: 20px;
      width: 21px; }
    .site-header .close:hover:before, .site-header .close:hover:after {
      background: #000; }
  .site-header .header-search {
    margin: 0;
    position: relative; }
    .site-header .header-search .input-field {
      margin: 0;
      padding: 0 42px; }
      .site-header .header-search .input-field .icon {
        left: 0;
        margin-top: -10px;
        position: absolute;
        text-align: left;
        top: 50%; }
      .site-header .header-search .input-field input {
        background: none;
        border: 0;
        border-radius: 0;
        height: 42px;
        padding: 12px 0 13px;
        width: 100%; }
        .site-header .header-search .input-field input:focus {
          background: none;
          color: #000;
          outline: none !important; }
  .site-header .header-social {
    margin: 0;
    position: relative; }
    .site-header .header-social .icons-list {
      height: 42px;
      margin: 0;
      padding: 0 42px; }
      .site-header .header-social .icons-list .icon {
        left: 0;
        margin-top: -10px;
        position: absolute;
        text-align: left;
        top: 50%; }
      .site-header .header-social .icons-list .social {
        padding-top: 10px;
        text-align: center; }
  .site-header.open-action .container .row .col,
  .site-header.open-action .container-fluid .row .col {
    border: 0 !important; }
    .site-header.open-action .container .row .col .logo,
    .site-header.open-action .container .row .col .menu,
    .site-header.open-action .container .row .col .action-box:not(.active),
    .site-header.open-action .container .row .col .header-actions:before,
    .site-header.open-action .container-fluid .row .col .logo,
    .site-header.open-action .container-fluid .row .col .menu,
    .site-header.open-action .container-fluid .row .col .action-box:not(.active),
    .site-header.open-action .container-fluid .row .col .header-actions:before {
      opacity: 0; }
    .site-header.open-action .container .row .col .action-box .header-btn,
    .site-header.open-action .container-fluid .row .col .action-box .header-btn {
      opacity: 0; }

@media (min-width: 768px) {
  .fixed-header .site-header,
  .dynamic-header .site-header {
    position: fixed;
    z-index: 999; }
  .dynamic-header .site-header.small-height {
    height: 42px;
    max-height: 42px; }
    .dynamic-header .site-header.small-height .menu .sub-menu-wrap {
      padding-top: 10px; } }

/******************************************************************************
  Transparent Header
*******************************************************************************/
.transparent-header:not(.scrolling) .site-header:not(.open-action) {
  background: transparent;
  box-shadow: none; }

.transparent-header:not(.scrolling) .site-header .menu .menu-btn span {
  background: #fff; }

.transparent-header:not(.scrolling) .site-header .menu:not(.collapsed) .menu-list > li > a {
  color: #fff; }

.transparent-header:not(.scrolling) .site-header .menu:not(.collapsed) .menu-list > li.menu-item-has-children > a:after {
  border-color: rgba(255, 255, 255, 0.6); }

.transparent-header:not(.scrolling) .site-header .menu:not(.collapsed) .menu-list > li:hover > a {
  color: #e17ab4; }

.transparent-header:not(.scrolling) .site-header .header-btn svg {
  fill: rgba(255, 255, 255, 0.6); }

/******************************************************************************
  Slider
*******************************************************************************/
.slider {
  color: #fff;
  height: auto;
  margin-bottom: 4rem;
  min-height: 350px;
  position: relative; }
  @media (max-width: 767px) {
    .slider {
      margin-bottom: 3rem; } }
  .slider .slides {
    min-height: inherit; }
  .slider .owl-stage-outer {
    min-height: inherit; }
    .slider .owl-stage-outer .owl-stage {
      height: inherit;
      min-height: inherit; }
      .slider .owl-stage-outer .owl-stage .owl-item {
        height: inherit;
        min-height: inherit; }
        .slider .owl-stage-outer .owl-stage .owl-item .slider-item {
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          height: inherit;
          min-height: inherit;
          position: relative; }
          .slider .owl-stage-outer .owl-stage .owl-item .slider-item img {
            visibility: hidden; }
  .slider .caption-wrap {
    display: table;
    height: 100%;
    left: 0;
    position: absolute;
    table-layout: fixed;
    top: 0;
    width: 100%; }
    .slider .caption-wrap .caption {
      background: rgba(0, 0, 0, 0.6);
      display: table-cell;
      padding: 4rem 3rem;
      vertical-align: middle;
      width: 100%; }
      @media (max-width: 767px) {
        .slider .caption-wrap .caption {
          padding: 3rem 1.5rem; } }
      .slider .caption-wrap .caption.top-align {
        vertical-align: top; }
      .slider .caption-wrap .caption.bottom-align {
        vertical-align: bottom; }
      .slider .caption-wrap .caption .title,
      .slider .caption-wrap .caption .subtitle,
      .slider .caption-wrap .caption .btn {
        margin-bottom: 3rem; }
        @media (max-width: 767px) {
          .slider .caption-wrap .caption .title,
          .slider .caption-wrap .caption .subtitle,
          .slider .caption-wrap .caption .btn {
            margin-bottom: 2rem; } }
        .slider .caption-wrap .caption .title:last-child,
        .slider .caption-wrap .caption .subtitle:last-child,
        .slider .caption-wrap .caption .btn:last-child {
          margin-bottom: 0 !important; }
      .slider .caption-wrap .caption .title {
        color: #fff;
        font-size: 54px;
        font-size: 3.85714rem;
        margin-bottom: 2rem; }
        @media (max-width: 991px) {
          .slider .caption-wrap .caption .title {
            font-size: 32px;
            font-size: 2.28571rem; } }
        @media (max-width: 767px) {
          .slider .caption-wrap .caption .title {
            font-size: 22px;
            font-size: 1.57143rem; } }
        .slider .caption-wrap .caption .title a {
          color: #fff; }
          .slider .caption-wrap .caption .title a:hover {
            color: #e17ab4; }
      .slider .caption-wrap .caption .subtitle {
        color: rgba(255, 255, 255, 0.8);
        font-size: 32px;
        font-size: 2.28571rem; }
        @media (max-width: 991px) {
          .slider .caption-wrap .caption .subtitle {
            font-size: 20px;
            font-size: 1.42857rem; } }
        @media (max-width: 767px) {
          .slider .caption-wrap .caption .subtitle {
            font-size: 16px;
            font-size: 1.14286rem; } }
      @media (max-width: 767px) {
        .slider .caption-wrap .caption .btn {
          display: none; } }
  .slider .owl-dots {
    bottom: 3rem;
    left: 0;
    margin: 0 -12px -5px;
    padding-right: 3rem;
    padding-left: 3rem;
    position: absolute;
    right: 0;
    text-align: right; }
    @media (max-width: 991px) {
      .slider .owl-dots {
        font-size: 32px;
        font-size: 2.28571rem; } }
    @media (max-width: 767px) {
      .slider .owl-dots {
        bottom: 1.5rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem; } }
    .slider .owl-dots .owl-dot span {
      background: rgba(255, 255, 255, 0.54); }
    .slider .owl-dots .owl-dot:hover span {
      background: #fff; }
    .slider .owl-dots .owl-dot.active span {
      background: #e17ab4; }
  .slider .counter {
    bottom: 3rem;
    font-size: 18px;
    font-size: 1.28571rem;
    left: 3rem;
    line-height: 1;
    position: absolute;
    text-transform: uppercase;
    z-index: 1; }
    @media (max-width: 767px) {
      .slider .counter {
        bottom: 1.5rem;
        font-size: 14px;
        font-size: 1rem;
        left: 1.5rem; } }
    .slider .counter span {
      color: #e17ab4; }
  .slider.slider-overlay .slides .owl-stage-outer {
    overflow: visible; }
    .slider.slider-overlay .slides .owl-stage-outer .owl-stage .owl-item .slider-item .caption-wrap .caption .title,
    .slider.slider-overlay .slides .owl-stage-outer .owl-stage .owl-item .slider-item .caption-wrap .caption .subtitle,
    .slider.slider-overlay .slides .owl-stage-outer .owl-stage .owl-item .slider-item .caption-wrap .caption .btn {
      opacity: 0;
      transform: translateY(5rem);
      transition: 0.2s opacity 0s ease-in-out, 0.2s transform 0.2s ease-in-out; }
    .slider.slider-overlay .slides .owl-stage-outer .owl-stage .owl-item.active .slider-item .caption-wrap .caption .title {
      opacity: 1;
      transform: translateY(0);
      transition: 0.4s opacity 0s ease-in-out, 0.4s transform 0s ease-in-out; }
    .slider.slider-overlay .slides .owl-stage-outer .owl-stage .owl-item.active .slider-item .caption-wrap .caption .subtitle {
      opacity: 1;
      transform: translateY(0);
      transition: 0.4s opacity 0.1s ease-in-out, 0.4s transform 0.1s ease-in-out; }
    .slider.slider-overlay .slides .owl-stage-outer .owl-stage .owl-item.active .slider-item .caption-wrap .caption .btn {
      opacity: 1;
      transform: translateY(0);
      transition: 0.4s opacity 0.2s ease-in-out, 0.4s transform 0.2s ease-in-out; }
  .slider.slider-overlay .slides .owl-nav .owl-prev,
  .slider.slider-overlay .slides .owl-nav .owl-next {
    background: rgba(255, 255, 255, 0.7);
    content: '';
    display: block;
    height: 100%;
    left: auto;
    margin: 0;
    opacity: 1;
    right: 100%;
    top: 0;
    transform: translate(0);
    transition: background 0.2s ease-in-out;
    width: 2000px;
    z-index: 2; }
    .slider.slider-overlay .slides .owl-nav .owl-prev:before, .slider.slider-overlay .slides .owl-nav .owl-prev:after,
    .slider.slider-overlay .slides .owl-nav .owl-next:before,
    .slider.slider-overlay .slides .owl-nav .owl-next:after {
      display: none; }
    .slider.slider-overlay .slides .owl-nav .owl-prev:hover,
    .slider.slider-overlay .slides .owl-nav .owl-next:hover {
      background: rgba(225, 122, 180, 0.2); }
  .slider.slider-overlay .slides .owl-nav .owl-next {
    left: 100%;
    right: auto; }
  .slider.slider-full-screen {
    height: 100vh; }
    .slider.slider-full-screen .slides {
      height: 100%; }
      .slider.slider-full-screen .slides .owl-stage-outer {
        height: 100vh !important; }

.full-screen-slider .page-box {
  padding-top: 0; }

.owl-dots {
  line-height: 0;
  margin-top: 11px;
  text-align: center; }
  .owl-dots .owl-dot {
    display: inline-block;
    margin: 0 2px;
    padding: 10px;
    vertical-align: top; }
    .owl-dots .owl-dot span {
      background: #666;
      border-radius: 50%;
      display: block;
      height: 6px;
      transition: background 0.3s ease-in-out;
      width: 6px; }
    .owl-dots .owl-dot:hover span {
      background: #000; }
    .owl-dots .owl-dot.active span {
      background: #e17ab4; }

.owl-nav .owl-prev,
.owl-nav .owl-next {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0;
  color: #fff;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  text-align: center;
  text-indent: -9999px;
  top: 50%;
  width: 40px; }
  .owl-nav .owl-prev:before,
  .owl-nav .owl-next:before {
    background: #fff;
    content: '';
    display: block;
    height: 2px;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    transition: background 0.2s ease-in-out;
    width: 25px; }
  .owl-nav .owl-prev:after,
  .owl-nav .owl-next:after {
    border: 3px solid transparent;
    border-width: 3px 4px;
    content: '';
    display: block;
    margin-top: -3px;
    position: absolute;
    top: 50%;
    transition: border 0.2s ease-in-out; }
  .owl-nav .owl-prev:hover:before,
  .owl-nav .owl-next:hover:before {
    background: #e17ab4; }

.owl-nav .owl-prev {
  left: 10px; }
  .owl-nav .owl-prev:before {
    left: 9px; }
  .owl-nav .owl-prev:after {
    border-right-color: #fff;
    left: 1px; }
  .owl-nav .owl-prev:hover:after {
    border-right-color: #e17ab4; }

.owl-nav .owl-next {
  right: 10px; }
  .owl-nav .owl-next:before {
    right: 9px; }
  .owl-nav .owl-next:after {
    border-left-color: #fff;
    right: 1px; }
  .owl-nav .owl-next:hover:after {
    border-left-color: #e17ab4; }

.owl-carousel .owl-item {
  backface-visibility: inherit; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out; }

.owl-carousel .owl-nav .owl-prev {
  transform: translateX(-20px); }

.owl-carousel .owl-nav .owl-next {
  transform: translateX(20px); }

.owl-carousel:hover .owl-nav .owl-prev,
.owl-carousel:hover .owl-nav .owl-next {
  opacity: 1;
  transform: translateX(0); }

.owl-height {
  transition: height 0.1s ease-in-out; }

.carousel-item {
  margin: 0 auto;
  max-width: 260px; }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  backface-visibility: hidden;
  transition: all 0.3s ease-in-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0; }

.mfp-close,
.mfp-arrow {
  transition: opacity 0.2s ease-in-out; }

.mfp-close {
  height: 30px;
  opacity: 1;
  width: 30px; }
  .mfp-close:before, .mfp-close:after {
    background: #fff;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    transition: background 0.2s ease-in-out; }
  .mfp-close:before {
    height: 2px;
    margin: -1px 0 0 -15px;
    width: 100%; }
  .mfp-close:after {
    height: 100%;
    margin: -15px 0 0 -1px;
    width: 2px; }
  .mfp-close:hover:before, .mfp-close:hover:after {
    background: #e17ab4; }

.mfp-arrow {
  margin-top: -55px !important;
  opacity: 1; }
  .mfp-arrow:before,
  .mfp-arrow .mfp-b {
    background: #fff;
    border: 0;
    height: 2px;
    left: 50%;
    margin: -1px 0 0 -14px;
    opacity: 1;
    top: 50%;
    transition: background 0.2s ease-in-out;
    width: 25px; }
  .mfp-arrow:after,
  .mfp-arrow .mfp-a {
    border-top-width: 3px;
    border-bottom-width: 3px;
    left: 50%;
    top: 50%;
    transition: border 0.2s ease-in-out; }
  .mfp-arrow:hover:before,
  .mfp-arrow:hover .mfp-b {
    background: #e17ab4; }

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
  border-right: 5px solid #fff;
  margin: -3px 0 0 -22px; }

.mfp-arrow-left:hover:after,
.mfp-arrow-left:hover .mfp-a {
  border-right-color: #e17ab4; }

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
  border-left: 5px solid #fff;
  margin: -3px 0 0 11px; }

.mfp-arrow-right:hover:after,
.mfp-arrow-right:hover .mfp-a {
  border-left-color: #e17ab4; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  font-size: 0;
  padding: 0;
  right: 20px;
  top: 20px;
  width: 30px; }

img.mfp-img {
  padding-top: 0;
  padding-bottom: 0; }

.mfp-bottom-bar {
  bottom: 0;
  margin-top: 0;
  top: auto; }
  .mfp-bottom-bar .mfp-counter {
    bottom: 20px;
    color: #fff;
    font-size: 18px;
    font-size: 1.28571rem;
    left: 20px;
    line-height: 1;
    text-transform: uppercase;
    top: auto; }
    .mfp-bottom-bar .mfp-counter span {
      color: #e17ab4; }

.arrow-left,
.arrow-right {
  display: inline-block;
  min-height: 6px;
  position: relative;
  vertical-align: middle;
  width: 29px; }
  .arrow-left:before,
  .arrow-right:before {
    background: #000;
    content: '';
    display: block;
    float: left;
    height: 2px;
    left: 0;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    transition: background 0.2s ease-in-out;
    width: 25px; }
  .arrow-left:after,
  .arrow-right:after {
    border: 3px solid transparent;
    border-width: 3px 4px;
    content: '';
    position: absolute;
    right: -4px;
    transition: border 0.2s ease-in-out; }
  .arrow-left:hover:before,
  .arrow-right:hover:before {
    background: #e17ab4; }

.arrow-right:after {
  border-left-color: #000; }

.arrow-right:hover:after {
  border-left-color: #e17ab4; }

.arrow-left:before {
  left: auto;
  right: 0; }

.arrow-left:after {
  border-right-color: #000;
  left: -4px;
  right: auto; }

.arrow-left:hover:after {
  border-right-color: #e17ab4; }

/******************************************************************************
  Banners
*******************************************************************************/
.banners {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  min-height: 100%; }
  .banners .banner {
    display: block;
    line-height: 1;
    margin: 0 auto 2rem;
    max-width: 100%;
    position: relative; }
    @media (max-width: 767px) {
      .banners .banner {
        margin-bottom: 1rem; } }
    .banners .banner:before {
      background: rgba(0, 0, 0, 0.6);
      bottom: 14px;
      content: '';
      left: 14px;
      position: absolute;
      right: 14px;
      top: 14px;
      transition: background 0.2s ease-in-out; }
    .banners .banner img {
      width: 100%; }
    .banners .banner .title {
      align-items: center;
      color: #fff;
      display: flex;
      font-size: 18px;
      font-size: 1.28571rem;
      height: 100%;
      justify-content: center;
      left: 0;
      padding: 2rem;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%; }
    .banners .banner:hover:before {
      background: rgba(225, 122, 180, 0.6); }

/******************************************************************************
  Footer
*******************************************************************************/
.site-footer {
  font-size: 12px;
  font-size: 0.85714rem;
  flex: 0 0 auto;
  line-height: 1.75;
  width: 100%; }
  .site-footer .footer-banners .banners-title {
    color: #ccc;
    font-size: 20px;
    font-size: 1.42857rem;
    text-align: center; }
  .site-footer .footer-banners > ul {
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0; }
    .site-footer .footer-banners > ul > li {
      float: left;
      width: 12.5%; }
      @media (max-width: 767px) {
        .site-footer .footer-banners > ul > li {
          width: 25%; } }
      .site-footer .footer-banners > ul > li a {
        display: block;
        position: relative;
        text-decoration: none; }
        .site-footer .footer-banners > ul > li a:before {
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: background 0.2s ease-in-out, bottom 0.2s ease-in-out, left 0.2s ease-in-out, right 0.2s ease-in-out, top 0.2s ease-in-out; }
        .site-footer .footer-banners > ul > li a .item-info {
          color: #fff;
          font-size: 14px;
          left: 0;
          list-style-type: none;
          line-height: 1;
          margin: 0;
          opacity: 0;
          padding: 0;
          position: absolute;
          right: 0;
          text-align: center;
          top: 50%;
          transition: 0.2s opacity 0s ease-in-out, 0.2s margin 0s ease-in-out; }
          @media (max-width: 991px) {
            .site-footer .footer-banners > ul > li a .item-info {
              display: none; } }
          .site-footer .footer-banners > ul > li a .item-info li {
            display: inline-block;
            margin: 0 5px;
            vertical-align: top; }
            .site-footer .footer-banners > ul > li a .item-info li:before {
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 14px;
              font-size: 16px;
              margin-right: 5px;
              vertical-align: top; }
            .site-footer .footer-banners > ul > li a .item-info li.likes:before {
              content: '\f08a'; }
            .site-footer .footer-banners > ul > li a .item-info li.comments:before {
              content: '\f0e5'; }
        .site-footer .footer-banners > ul > li a:hover:before {
          background: rgba(0, 0, 0, 0.6);
          bottom: 7px;
          left: 7px;
          right: 7px;
          top: 7px; }
        .site-footer .footer-banners > ul > li a:hover .item-info {
          margin-top: -7px;
          opacity: 1;
          transition: 0.2s opacity 0.2s ease-in-out, 0.2s margin 0.2s ease-in-out; }
  .site-footer .container {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
    @media (max-width: 767px) {
      .site-footer .container {
        padding-top: 1rem;
        padding-bottom: 1rem; } }
  .site-footer .footer-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .site-footer .footer-menu ul li {
      display: inline-block;
      margin: 0 5px;
      vertical-align: top; }
  .site-footer .footer-sidebar {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0; }
    .site-footer .footer-sidebar .widget {
      padding-left: 0;
      padding-right: 0; }
  .site-footer > hr {
    margin: 0;
    padding: 0; }

/******************************************************************************
  Animation
*******************************************************************************/
.animating {
  opacity: 0;
  transition: opacity 0.2s ease-in-out; }
  .animating.animated {
    opacity: 1; }

/******************************************************************************
  Buttons
*******************************************************************************/
.btn {
  border-radius: 0;
  font-size: 12px;
  font-size: 0.85714rem;
  font-weight: 600;
  letter-spacing: .175em;
  padding: 12px 40px;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out; }
  @media (max-width: 767px) {
    .btn {
      padding: 8px 20px; } }
  .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none; }
  .btn.btn-default {
    background-color: #e17ab4;
    border-color: #e17ab4;
    color: #fff; }
    .btn.btn-default:hover {
      background-color: transparent;
      color: #e17ab4; }
  .btn.btn-outline-default {
    background-color: transparent;
    border-color: #e17ab4;
    color: #e17ab4; }
    .btn.btn-outline-default:hover {
      background-color: #e17ab4;
      color: #fff; }

/******************************************************************************
  Forms
*******************************************************************************/
.form-control {
  border-color: #ccc;
  border-radius: 0;
  color: #000;
  padding: 10px 20px 11px;
  transition: border 0.2s ease-in-out; }
  .form-control::-webkit-input-placeholder {
    color: #999;
    font-style: italic; }
  .form-control:-moz-placeholder {
    color: #999;
    font-style: italic; }
  .form-control::-moz-placeholder {
    color: #999;
    font-style: italic; }
  .form-control:-ms-input-placeholder {
    color: #999;
    font-style: italic; }
  @media (max-width: 767px) {
    .form-control {
      padding-top: 6px;
      padding-bottom: 7px; } }
  .form-control[type="number"] {
    padding-right: 10px; }
  .form-control:focus {
    border-color: #000; }

textarea {
  height: 6rem; }

::-webkit-input-placeholder {
  color: #999;
  font-style: italic; }

:-moz-placeholder {
  color: #999;
  font-style: italic; }

::-moz-placeholder {
  color: #999;
  font-style: italic; }

:-ms-input-placeholder {
  color: #999;
  font-style: italic; }

.control-label {
  cursor: pointer;
  font-style: italic;
  line-height: 1.4; }

select.form-control:not([size]):not([multiple]) {
  height: auto;
  padding-top: 8px;
  padding-bottom: 9px; }
  @media (max-width: 767px) {
    select.form-control:not([size]):not([multiple]) {
      padding-top: 4px;
      padding-bottom: 5px; } }

.checkout-forms {
  cursor: pointer;
  font-style: italic;
  line-height: 1.5;
  margin: 0;
  overflow: hidden;
  vertical-align: top; }
  .checkout-forms input[type="checkbox"] {
    position: absolute;
    left: -9999px; }
    .checkout-forms input[type="checkbox"]:checked + .new-checkbox:before {
      transform: scale(1); }
  .checkout-forms .new-checkbox {
    background: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    height: 1.5rem;
    margin: 0 0.75rem 0 0;
    position: relative;
    vertical-align: top;
    width: 1.5rem; }
    .checkout-forms .new-checkbox:before {
      background: #e17ab4;
      bottom: 3px;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      transform: scale(0);
      transition: transform 0.2s ease-in-out; }

/******************************************************************************
  Other Elements
*******************************************************************************/
.contact-form .form-submit {
  margin: 0 -10px; }
  @media (max-width: 767px) {
    .contact-form .form-submit {
      margin: 0; } }
  .contact-form .form-submit .btn,
  .contact-form .form-submit .form-message {
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle; }
    @media (max-width: 767px) {
      .contact-form .form-submit .btn,
      .contact-form .form-submit .form-message {
        display: block;
        margin: 0;
        text-align: center;
        width: 100%; } }
  .contact-form .form-submit .form-message {
    display: none;
    font-size: 14px;
    font-size: 1rem; }
    @media (max-width: 767px) {
      .contact-form .form-submit .form-message span {
        display: block;
        margin-top: 10px; } }

.contacts-list {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .contacts-list li {
    padding: 5px 0; }
    .contacts-list li .material-icons {
      margin-right: 10px;
      vertical-align: middle; }

/******************************************************************************
  Pagination
*******************************************************************************/
.pager {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0; }
  .pager li {
    display: block; }
    .pager li > a,
    .pager li > span {
      background: none;
      border: 0;
      color: #000;
      font-size: 16px;
      font-size: 1.14286rem;
      padding: 0; }
      .pager li > a:hover, .pager li > a:focus,
      .pager li > span:hover,
      .pager li > span:focus {
        background: none;
        color: #e17ab4; }
        .pager li > a:hover .arrow-left:before, .pager li > a:focus .arrow-left:before,
        .pager li > span:hover .arrow-left:before,
        .pager li > span:focus .arrow-left:before {
          background: #e17ab4; }
        .pager li > a:hover .arrow-left:after, .pager li > a:focus .arrow-left:after,
        .pager li > span:hover .arrow-left:after,
        .pager li > span:focus .arrow-left:after {
          border-right-color: #e17ab4; }
        .pager li > a:hover .arrow-right:before, .pager li > a:focus .arrow-right:before,
        .pager li > span:hover .arrow-right:before,
        .pager li > span:focus .arrow-right:before {
          background: #e17ab4; }
        .pager li > a:hover .arrow-right:after, .pager li > a:focus .arrow-right:after,
        .pager li > span:hover .arrow-right:after,
        .pager li > span:focus .arrow-right:after {
          border-left-color: #e17ab4; }

.pagination {
  border-radius: 0;
  display: block;
  margin: 0; }
  .pagination .page-numbers {
    line-height: 1;
    list-style-type: none;
    margin: 0;
    padding: 0;
    vertical-align: top; }
    .pagination .page-numbers li {
      display: inline-block;
      margin: 0 2px;
      vertical-align: top; }
      .pagination .page-numbers li a,
      .pagination .page-numbers li span {
        background: #e5e5e5;
        border: 1px solid #e5e5e5;
        color: #666;
        display: block;
        height: 24px;
        line-height: 1;
        min-width: 24px;
        padding: 4px 3px;
        text-align: center;
        transition: background 0.2s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out;
        vertical-align: baseline; }
      .pagination .page-numbers li a:hover {
        background: transparent;
        border-color: #e17ab4;
        color: #e17ab4; }
      .pagination .page-numbers li .current {
        background: #e17ab4;
        border-color: #e17ab4;
        color: #fff; }

/******************************************************************************
  Social
*******************************************************************************/
.social {
  line-height: 1;
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .social li {
    display: inline-block;
    margin: 0 5px; }
    .social li a {
      color: #ccc;
      display: block;
      font-size: 14px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
      width: 24px; }
      .social li a:hover {
        background: #e5e5e5;
        color: #666; }

/******************************************************************************
  Tabs
*******************************************************************************/
.nav-tabs {
  border: 0; }
  @media (max-width: 767px) {
    .nav-tabs {
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-bottom-color: #000; } }
  .nav-tabs .nav-item {
    margin: 0 2px 0 0; }
    @media (max-width: 767px) {
      .nav-tabs .nav-item {
        float: none;
        margin: 0 !important; } }
    .nav-tabs .nav-item + .nav-item {
      margin: 0 2px 0 0; }
    .nav-tabs .nav-item .nav-link {
      border: 0;
      border-radius: 0;
      color: inherit;
      padding: 1rem 3rem; }
      @media (max-width: 767px) {
        .nav-tabs .nav-item .nav-link {
          padding: 0.75rem 1.5rem; } }
      .nav-tabs .nav-item .nav-link:hover {
        color: #e17ab4; }
      .nav-tabs .nav-item .nav-link.active {
        background: rgba(0, 0, 0, 0.05);
        border: 0;
        color: #000; }

.tab-content {
  background: rgba(0, 0, 0, 0.05);
  padding: 3rem; }
  @media (max-width: 767px) {
    .tab-content {
      padding: 1.5rem; } }

/******************************************************************************
  Grid Posts
*******************************************************************************/
.grid-layout .grid-item .post {
  margin-left: auto;
  margin-right: auto;
  max-width: 528px; }
  .grid-layout .grid-item .post .post-media {
    margin-bottom: 3rem; }
  .grid-layout .grid-item .post .post-header {
    margin-bottom: 2rem; }
    @media (min-width: 768px) {
      .grid-layout .grid-item .post .post-header {
        padding-left: 2rem;
        padding-right: 2rem; } }
    .grid-layout .grid-item .post .post-header:first-child {
      padding-top: 2rem; }
    .grid-layout .grid-item .post .post-header .tags {
      margin-bottom: 1rem; }
    .grid-layout .grid-item .post .post-header .post-title {
      margin-bottom: 1rem;
      font-size: 24px;
      font-size: 1.71429rem; }
      @media (max-width: 767px) {
        .grid-layout .grid-item .post .post-header .post-title {
          font-size: 22px;
          font-size: 1.57143rem; } }
  .grid-layout .grid-item .post .post-content {
    margin-bottom: 1.5rem; }
    @media (min-width: 768px) {
      .grid-layout .grid-item .post .post-content {
        padding-left: 2rem;
        padding-right: 2rem; } }
  @media (min-width: 768px) {
    .grid-layout .grid-item .post .post-footer .post-author,
    .grid-layout .grid-item .post .post-footer .post-sharing {
      padding-left: 2rem;
      padding-right: 2rem; } }

/******************************************************************************
  List Posts
*******************************************************************************/
@media (max-width: 543px) {
  .list-layout .post {
    margin-left: auto;
    margin-right: auto;
    max-width: 350px; } }

.list-layout .post .post-entry {
  padding-top: 3rem;
  padding-bottom: 3rem; }
  @media (max-width: 767px) {
    .list-layout .post .post-entry {
      padding-left: 0; } }
  @media (max-width: 543px) {
    .list-layout .post .post-entry {
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: 1.5rem; } }

.list-layout .post .post-media {
  margin: 0; }
  .list-layout .post .post-media a {
    display: block;
    height: 100%;
    width: 100%; }
    .list-layout .post .post-media a img {
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%; }

.list-layout .post .post-header {
  margin-bottom: 3rem;
  padding-left: 0;
  padding-top: 0 !important;
  text-align: left; }
  @media (max-width: 1199px) {
    .list-layout .post .post-header {
      margin-bottom: 2rem; } }
  @media (max-width: 543px) {
    .list-layout .post .post-header {
      padding-left: 1.5rem; } }
  @media (max-width: 1199px) {
    .list-layout .post .post-header .tags {
      margin-bottom: 1rem; } }
  @media (max-width: 1199px) {
    .list-layout .post .post-header .post-title {
      margin-bottom: 1rem; } }

.list-layout .post .post-content {
  margin-bottom: 0;
  overflow: hidden;
  padding-left: 0; }
  @media (max-width: 543px) {
    .list-layout .post .post-content {
      max-height: inherit;
      padding-left: 1.5rem; } }

.list-layout .post .post-footer .post-author {
  padding-left: 0; }

/******************************************************************************
  Masonry Posts
*******************************************************************************/
.masonry-layout {
  display: block; }
  .masonry-layout .grid-sizer {
    float: left;
    min-height: 0; }
  .masonry-layout .grid-item {
    float: left; }

/******************************************************************************
  Modern Posts
*******************************************************************************/
.modern-list-layout .post > .row {
  align-items: center; }

@media (min-width: 992px) {
  .modern-list-layout .post .post-media {
    padding-left: 5rem;
    padding-right: 5rem; } }

@media (max-width: 543px) {
  .modern-list-layout .post .post-media {
    margin-bottom: 2rem; } }

.modern-list-layout .post .post-media a {
  height: auto; }
  .modern-list-layout .post .post-media a img {
    height: auto; }

.modern-list-layout .post .post-entry {
  padding-top: 0;
  padding-bottom: 0; }

.modern-list-layout .post .post-header {
  padding: 0; }

.modern-list-layout .post .post-content {
  padding: 0; }

@media (min-width: 544px) {
  .modern-list-layout .post:nth-child(2n) .post-media {
    order: 1; } }

/******************************************************************************
  Post Comments
*******************************************************************************/
.post-comments {
  background: rgba(0, 0, 0, 0.05);
  padding: 3rem; }
  @media (max-width: 767px) {
    .post-comments {
      padding: 1.5rem; } }
  .post-comments .title {
    margin-bottom: 2rem; }

.comments {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .comments .comment {
    overflow: hidden;
    padding: 0 0 1.5rem;
    position: relative; }
    .comments .comment:last-child {
      padding-bottom: 0; }
    .comments .comment .author-img {
      float: left;
      height: 50px;
      margin: 0 1.5rem 1.5rem 0;
      width: 50px; }
      .comments .comment .author-img img {
        border-radius: 50%; }
    .comments .comment .reply {
      position: absolute;
      right: 0;
      top: 0; }
      .comments .comment .reply a {
        font-size: 12px;
        font-style: italic; }
    .comments .comment .author {
      margin-bottom: 5px; }
    .comments .comment .date {
      font-style: italic; }
    .comments .comment p {
      clear: both; }
    .comments .comment .children {
      list-style-type: none;
      margin: 0;
      padding: 1.5rem 0 0 2rem; }
      .comments .comment .children .author-img {
        height: 40px;
        width: 40px; }

.comment-respond {
  margin-top: 4rem; }
  @media (max-width: 767px) {
    .comment-respond {
      margin-top: 3rem; } }
  .comment-respond .comment-reply-title {
    margin-bottom: 1.5rem; }
  .comment-respond .comment-form {
    margin-bottom: 0; }
    .comment-respond .comment-form .comment-notes {
      font-size: 90%; }
    .comment-respond .comment-form .left-field {
      float: left;
      padding-right: 15px;
      width: 50%; }
      @media (max-width: 543px) {
        .comment-respond .comment-form .left-field {
          float: none;
          padding-right: 0;
          width: 100%; } }
    .comment-respond .comment-form .right-field {
      float: right;
      padding-left: 15px;
      width: 50%; }
      @media (max-width: 543px) {
        .comment-respond .comment-form .right-field {
          float: none;
          padding-left: 0;
          width: 100%; } }
    .comment-respond .comment-form .btn-form-group {
      clear: both;
      padding-top: 2rem; }
    @media (max-width: 543px) {
      .comment-respond .comment-form .submit {
        display: block;
        width: 100%; } }

/******************************************************************************
  Post
*******************************************************************************/
.post {
  margin-bottom: 4rem;
  padding-bottom: 0 !important; }
  @media (max-width: 767px) {
    .post {
      margin-bottom: 3rem; } }
  .post .post-media {
    margin-bottom: 6rem;
    position: relative;
    text-align: center; }
    @media (max-width: 767px) {
      .post .post-media {
        margin-bottom: 2rem; } }
    .post .post-media > a {
      display: block;
      position: relative; }
      .post .post-media > a:before {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: background 0.2s ease-in-out, bottom 0.2s ease-in-out, left 0.2s ease-in-out, right 0.2s ease-in-out, top 0.2s ease-in-out; }
      .post .post-media > a:hover:before {
        background: rgba(0, 0, 0, 0.6);
        bottom: 14px;
        left: 14px;
        right: 14px;
        top: 14px; }
    .post .post-media .guote {
      background-color: rgba(0, 0, 0, 0.54);
      color: #fff;
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
      .post .post-media .guote .cell-vertical {
        display: table;
        height: 100%;
        width: 100%; }
        .post .post-media .guote .cell-vertical .cell-middle {
          display: table-cell;
          padding: 28px 70px;
          vertical-align: middle; }
          @media (max-width: 767px) {
            .post .post-media .guote .cell-vertical .cell-middle {
              padding: 14px; } }
          .post .post-media .guote .cell-vertical .cell-middle .icon {
            border: 1px solid #fff;
            border-radius: 50%;
            display: inline-block;
            font-size: 26px;
            font-size: 1.85714rem;
            height: 50px;
            line-height: 50px;
            margin-bottom: 10px;
            text-align: center;
            width: 50px; }
          .post .post-media .guote .cell-vertical .cell-middle .text {
            font-size: 32px;
            font-size: 2.28571rem;
            margin-bottom: 0; }
            @media (max-width: 991px) {
              .post .post-media .guote .cell-vertical .cell-middle .text {
                font-size: 24px;
                font-size: 1.71429rem; } }
            @media (max-width: 767px) {
              .post .post-media .guote .cell-vertical .cell-middle .text {
                font-size: 16px;
                font-size: 1.14286rem; } }
            .post .post-media .guote .cell-vertical .cell-middle .text:before, .post .post-media .guote .cell-vertical .cell-middle .text:after {
              color: rgba(255, 255, 255, 0.6); }
  .post .post-header {
    margin-bottom: 5rem;
    padding: 0 3rem;
    text-align: center; }
    @media (max-width: 767px) {
      .post .post-header {
        margin-bottom: 2rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    .post .post-header:first-child {
      padding-top: 5rem; }
      @media (max-width: 767px) {
        .post .post-header:first-child {
          padding-top: 2rem; } }
    .post .post-header .tags {
      font-size: 13px;
      font-size: 0.92857rem;
      line-height: 1;
      letter-spacing: .150em;
      margin-bottom: 2rem; }
      @media (max-width: 767px) {
        .post .post-header .tags {
          margin-bottom: 1rem; } }
    .post .post-header .post-title {
      font-size: 32px;
      font-size: 2.28571rem;
      margin-bottom: 2rem; }
      @media (max-width: 767px) {
        .post .post-header .post-title {
          font-size: 22px;
          font-size: 1.57143rem;
          margin-bottom: 1rem; } }
      .post .post-header .post-title a {
        color: #000; }
        .post .post-header .post-title a:hover {
          color: #e17ab4; }
    .post .post-header .date {
      line-height: 1; }
  .post .post-content {
    margin-bottom: 3rem;
    padding: 0 3rem; }
    @media (max-width: 767px) {
      .post .post-content {
        margin-bottom: 2rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    .post .post-content .more {
      margin-top: 5rem; }
      @media (max-width: 767px) {
        .post .post-content .more {
          margin-top: 2rem; } }
  .post .post-footer {
    border-top: 1px solid #ccc;
    color: #000;
    font-size: 13px;
    font-size: 0.92857rem;
    letter-spacing: .150em;
    margin: 0;
    padding: 1rem 0;
    position: relative;
    text-transform: uppercase; }
    .post .post-footer:after {
      content: '';
      display: table;
      width: 100%; }
    .post .post-footer .post-author {
      padding-left: 3rem;
      padding-right: 1.5rem; }
      @media (max-width: 767px) {
        .post .post-footer .post-author {
          padding-left: 1.5rem; } }
    .post .post-footer .post-sharing {
      padding-left: 1.5rem;
      padding-right: 3rem; }
      @media (max-width: 767px) {
        .post .post-footer .post-sharing {
          padding-right: 1.5rem; } }
      .post .post-footer .post-sharing .social {
        display: inline-block;
        letter-spacing: 0; }
        .post .post-footer .post-sharing .social li:last-child {
          margin-right: 0; }

.tags-list {
  font-size: 0;
  margin: 5rem -4px -4px;
  padding: 0; }
  .tags-list li {
    display: inline-block;
    margin: 4px;
    vertical-align: top; }
    .tags-list li a {
      background: #f5f5f5;
      border: 1px solid #f5f5f5;
      color: #8f8f8f;
      display: block;
      font-size: 12px;
      font-size: 0.85714rem;
      padding: 7px 20px 6px;
      transition: background 0.2s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out;
      text-transform: uppercase; }
      .tags-list li a:hover {
        background: transparent;
        border-color: #e17ab4;
        color: #e17ab4; }

.post-nav {
  margin-bottom: 4rem; }
  @media (max-width: 767px) {
    .post-nav {
      margin-bottom: 3rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }
  .post-nav a {
    color: #000;
    display: inline-block;
    vertical-align: top; }
    .post-nav a .arrow-left {
      margin-right: 10px; }
    .post-nav a .arrow-right {
      margin-left: 10px; }
    .post-nav a:hover {
      color: #e17ab4; }
      .post-nav a:hover .arrow-left:before,
      .post-nav a:hover .arrow-right:before {
        background: #e17ab4; }
      .post-nav a:hover .arrow-left:after {
        border-right-color: #e17ab4; }
      .post-nav a:hover .arrow-right:after {
        border-left-color: #e17ab4; }

/******************************************************************************
  Related Posts
*******************************************************************************/
.related-posts .title {
  margin-bottom: 2rem; }

.related-posts .post {
  margin-left: auto;
  margin-right: auto;
  max-width: 350px; }
  .related-posts .post .post-media {
    margin-bottom: 1.5rem; }
  .related-posts .post .post-header {
    margin-bottom: 0;
    padding: 0 1.5rem 1.5rem; }
    .related-posts .post .post-header .post-title {
      font-size: 18px;
      font-size: 1.28571rem;
      margin-bottom: 1rem; }
    .related-posts .post .post-header .date {
      line-height: 1.3; }

/******************************************************************************
  Social Posts
*******************************************************************************/
.posts-list .social-layout .post .author-info {
  padding: 2rem; }
  @media (max-width: 767px) {
    .posts-list .social-layout .post .author-info {
      padding: 1.5rem; } }
  .posts-list .social-layout .post .author-info .avatar {
    border-radius: 50%;
    float: left;
    height: 50px;
    margin: 0 21px 0 0;
    overflow: hidden;
    width: 50px; }
  .posts-list .social-layout .post .author-info .user-name {
    font-size: 14px;
    font-size: 1rem;
    margin-bottom: .5em; }
    .posts-list .social-layout .post .author-info .user-name a {
      color: #000; }
      .posts-list .social-layout .post .author-info .user-name a:hover {
        color: #e17ab4; }

.posts-list .social-layout .post .post-media {
  margin-bottom: 1.5rem; }

.posts-list .social-layout .post .post-header {
  margin-bottom: 1.5rem;
  text-align: left; }
  .posts-list .social-layout .post .post-header .post-title {
    font-size: 20px;
    font-size: 1.42857rem; }
    @media (max-width: 767px) {
      .posts-list .social-layout .post .post-header .post-title {
        font-size: 18px;
        font-size: 1.28571rem; } }
    .posts-list .social-layout .post .post-header .post-title a {
      color: #000; }
      .posts-list .social-layout .post .post-header .post-title a:hover {
        color: #e17ab4; }

.posts-list .social-layout .post .post-content {
  font-size: 12px;
  font-size: 0.85714rem;
  margin-bottom: 1.5rem; }

.posts-list .social-layout .post .post-footer {
  padding: 0.5rem 0; }
  .posts-list .social-layout .post .post-footer .social {
    margin: 0 -4px;
    padding: 0 1.5rem; }
    @media (max-width: 767px) {
      .posts-list .social-layout .post .post-footer .social {
        margin: 0 -9px; } }

.posts-list .social-layout .post.followers {
  color: #fff;
  padding: 2rem !important;
  position: relative;
  text-align: center; }
  @media (max-width: 767px) {
    .posts-list .social-layout .post.followers {
      padding: 1.5rem !important; } }
  .posts-list .social-layout .post.followers.twitter {
    background: #00aced; }
  .posts-list .social-layout .post.followers.facebook {
    background: #3B5998; }
  .posts-list .social-layout .post.followers.google {
    background: #dd4b39; }
  .posts-list .social-layout .post.followers.linkedin {
    background: #007bb6; }
  .posts-list .social-layout .post.followers.youtube {
    background: #bb0000; }
  .posts-list .social-layout .post.followers .post-icon {
    font-size: 18px;
    left: 2rem;
    position: absolute;
    top: 2rem; }
    @media (max-width: 767px) {
      .posts-list .social-layout .post.followers .post-icon {
        left: 1.5rem;
        top: 1.5rem; } }
  .posts-list .social-layout .post.followers .social-data .count {
    font-size: 40px;
    font-size: 2.85714rem; }

/******************************************************************************
  Timeline Posts
*******************************************************************************/
.timeline-layout {
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative; }
  .timeline-layout:before {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    content: "";
    display: block;
    height: 100%;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 0;
    width: 4px; }
    @media (max-width: 767px) {
      .timeline-layout:before {
        left: 1.5rem;
        margin-left: 18px; } }
  .timeline-layout .post {
    margin: 0;
    padding: 5px 40px 5px 0 !important;
    position: relative;
    width: 50%; }
    @media (max-width: 767px) {
      .timeline-layout .post {
        margin: 0;
        padding: 21px 0 42px 60px !important;
        width: 100%; }
        .timeline-layout .post:last-child {
          padding-bottom: 21px !important; } }
    .timeline-layout .post:before, .timeline-layout .post:after {
      border: 15px solid transparent;
      border-left-color: rgba(0, 0, 0, 0.07);
      content: "";
      display: block;
      position: absolute;
      right: 9px;
      top: 31px; }
      @media (max-width: 767px) {
        .timeline-layout .post:before, .timeline-layout .post:after {
          border: 15px solid transparent;
          border-right-color: rgba(0, 0, 0, 0.07);
          left: 29px;
          right: auto;
          top: 47px; } }
    .timeline-layout .post:after {
      border: 14px solid transparent;
      border-left-color: #fff;
      right: 12px;
      top: 32px; }
      @media (max-width: 767px) {
        .timeline-layout .post:after {
          border: 14px solid transparent;
          border-right-color: #fff;
          left: 32px;
          right: auto;
          top: 48px; } }
    .timeline-layout .post .post-wrap {
      padding: 0;
      position: relative; }
      .timeline-layout .post .post-wrap .date {
        font-style: italic;
        left: 100%;
        margin: 28px 0 0 80px;
        position: absolute;
        width: 100%; }
        @media (max-width: 767px) {
          .timeline-layout .post .post-wrap .date {
            bottom: 100%;
            left: 0 !important;
            margin: 0 0 4px !important;
            text-align: center !important;
            white-space: nowrap; } }
      .timeline-layout .post .post-wrap .post-icon {
        background: #e17ab4;
        border-radius: 20px;
        color: #fff;
        font-size: 18px;
        height: 40px;
        left: 100%;
        line-height: 40px;
        margin: 0 0 0 20px;
        position: absolute;
        text-align: center;
        top: 21px;
        width: 40px; }
        @media (max-width: 767px) {
          .timeline-layout .post .post-wrap .post-icon {
            left: auto;
            margin: 0 20px 0 0;
            right: 100%; } }
      .timeline-layout .post .post-wrap .post-media {
        margin-bottom: 2rem; }
      .timeline-layout .post .post-wrap .post-header {
        margin-bottom: 2rem; }
        @media (min-width: 768px) {
          .timeline-layout .post .post-wrap .post-header {
            padding-left: 2rem;
            padding-right: 2rem; } }
        .timeline-layout .post .post-wrap .post-header:first-child {
          padding-top: 2rem; }
        .timeline-layout .post .post-wrap .post-header .tags {
          margin-bottom: 1rem; }
        .timeline-layout .post .post-wrap .post-header .post-title {
          margin-bottom: 1rem;
          font-size: 24px;
          font-size: 1.71429rem; }
          @media (max-width: 767px) {
            .timeline-layout .post .post-wrap .post-header .post-title {
              font-size: 22px;
              font-size: 1.57143rem; } }
      .timeline-layout .post .post-wrap .post-content {
        margin-bottom: 2rem; }
        @media (min-width: 768px) {
          .timeline-layout .post .post-wrap .post-content {
            padding-left: 2rem;
            padding-right: 2rem; } }
        .timeline-layout .post .post-wrap .post-content .post-header .post-title {
          font-size: 16px;
          font-size: 1.14286rem; }
        .timeline-layout .post .post-wrap .post-content .post-entry {
          margin-bottom: 0; }
      @media (min-width: 768px) {
        .timeline-layout .post .post-wrap .post-footer .post-author,
        .timeline-layout .post .post-wrap .post-footer .post-sharing {
          padding-left: 2rem;
          padding-right: 2rem; } }
    .timeline-layout .post:nth-child(even) {
      margin-left: 50%;
      padding: 5px 0 5px 40px !important; }
      @media (max-width: 767px) {
        .timeline-layout .post:nth-child(even) {
          margin: 0;
          padding: 21px 0 42px 60px !important;
          width: 100%; }
          .timeline-layout .post:nth-child(even):last-child {
            padding-bottom: 21px !important; } }
      .timeline-layout .post:nth-child(even):before, .timeline-layout .post:nth-child(even):after {
        border: 15px solid transparent;
        border-right-color: rgba(0, 0, 0, 0.07);
        left: 9px;
        right: auto; }
        @media (max-width: 767px) {
          .timeline-layout .post:nth-child(even):before, .timeline-layout .post:nth-child(even):after {
            left: 29px; } }
      .timeline-layout .post:nth-child(even):after {
        border: 14px solid transparent;
        border-right-color: #fff;
        left: 12px;
        right: auto; }
        @media (max-width: 767px) {
          .timeline-layout .post:nth-child(even):after {
            left: 32px; } }
      .timeline-layout .post:nth-child(even) .post-wrap .date {
        left: auto;
        margin: 28px 80px 0 0;
        right: 100%;
        text-align: right; }
      .timeline-layout .post:nth-child(even) .post-wrap .post-icon {
        left: auto;
        margin: 0 20px 0 0;
        right: 100%; }

/******************************************************************************
  Sidebar
*******************************************************************************/
.sidebar {
  padding-left: 3rem; }
  @media (max-width: 991px) {
    .sidebar {
      margin-top: 4rem;
      padding-left: 1.07145rem !important;
      padding-right: 1.07145rem !important; } }
  @media (max-width: 767px) {
    .sidebar {
      margin-bottom: 2rem; } }
  .sidebar .widget {
    margin: 0 auto 4rem;
    max-width: 325px; }
    @media (max-width: 991px) {
      .sidebar .widget {
        margin-bottom: 2rem; } }

/******************************************************************************
  Widgets
*******************************************************************************/
.widget {
  padding: 1.5rem; }
  .widget:last-child {
    margin-bottom: 0; }
  .widget .widget-title {
    color: #000;
    font-size: 21px;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    padding-bottom: 10px;
    position: relative;
    text-align: center; }
    .widget .widget-title span {
      background: #fff;
      padding: 0 10px;
      position: relative; }
    .widget .widget-title:before {
      border: 1px solid #ccc;
      bottom: 0;
      content: '';
      height: 24px;
      left: 0;
      position: absolute;
      width: 100%; }
  .widget ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  .widget.about-me-widget {
    text-align: center; }
    .widget.about-me-widget .about-img {
      margin: 0 0 5rem; }
      .widget.about-me-widget .about-img img {
        border-radius: 50%; }
    .widget.about-me-widget a {
      font-style: italic; }
  .widget.social-widget .social {
    text-align: center; }
  .widget .menu {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .widget .menu li {
      border-bottom: 1px dashed #ccc;
      color: #9e9e9e;
      padding: 10px 28px;
      position: relative; }
      .widget .menu li:last-child {
        border-bottom: 0; }
      .widget .menu li a {
        color: #000; }
        .widget .menu li a:hover {
          color: #e17ab4; }
      .widget .menu li.active:before {
        border: 1px solid #e17ab4;
        border-radius: 50%;
        content: '';
        height: 6px;
        left: 1px;
        margin-top: -3px;
        position: absolute;
        top: 50%;
        width: 6px; }
      .widget .menu li.active a {
        color: #e17ab4; }
  .widget.latest-posts-widget ul li {
    overflow: hidden;
    padding: 15px 0; }
    .widget.latest-posts-widget ul li:first-child {
      padding-top: 0; }
    .widget.latest-posts-widget ul li:last-child {
      padding-bottom: 0; }
    .widget.latest-posts-widget ul li .post-img {
      display: block;
      height: 68px;
      float: left;
      width: 68px; }
      @media (max-width: 1199px) and (min-width: 992px) {
        .widget.latest-posts-widget ul li .post-img {
          height: 52px;
          width: 52px; } }
    .widget.latest-posts-widget ul li .post-content {
      overflow: hidden;
      padding-left: 30px; }
      .widget.latest-posts-widget ul li .post-content .post-title {
        font-size: 16px;
        font-size: 1.14286rem;
        margin-bottom: .5em; }
        .widget.latest-posts-widget ul li .post-content .post-title a {
          color: #000; }
          .widget.latest-posts-widget ul li .post-content .post-title a:hover {
            color: #e17ab4; }
      .widget.latest-posts-widget ul li .post-content .date {
        font-size: 12px;
        font-size: 0.85714rem; }
  .widget.banner-widget {
    overflow: hidden;
    padding: 0;
    position: relative; }
    .widget.banner-widget .banner-img {
      display: block; }
    .widget.banner-widget .banner-link {
      cursor: pointer;
      display: block;
      height: 100%;
      left: 0;
      padding: 28px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%; }
      .widget.banner-widget .banner-link .cell-vertical-wrapper {
        background: rgba(0, 0, 0, 0.6);
        display: table;
        height: 100%;
        transition: background 0.2s ease-in-out;
        width: 100%; }
        .widget.banner-widget .banner-link .cell-vertical-wrapper .cell-middle {
          color: #fff;
          display: table-cell;
          height: 100%;
          vertical-align: middle; }
          .widget.banner-widget .banner-link .cell-vertical-wrapper .cell-middle .title {
            color: #fff; }
            .widget.banner-widget .banner-link .cell-vertical-wrapper .cell-middle .title:only-child {
              margin-bottom: 0; }
      .widget.banner-widget .banner-link:hover .cell-vertical-wrapper {
        background: rgba(225, 122, 180, 0.6); }
  .widget.gallery-widget ul {
    margin: -3px;
    overflow: hidden; }
    .widget.gallery-widget ul li {
      float: left;
      padding: 3px;
      width: 33.33333333%; }
      .widget.gallery-widget ul li a {
        display: block;
        height: auto;
        width: 100%; }
  .widget .subscribe .form-group {
    position: relative; }
    .widget .subscribe .form-group .form-message {
      bottom: 100%;
      font-size: .9rem;
      margin-bottom: .1rem;
      position: absolute; }

.gallery-item {
  position: relative; }
  .gallery-item:before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: background 0.2s ease-in-out, bottom 0.2s ease-in-out, left 0.2s ease-in-out, right 0.2s ease-in-out, top 0.2s ease-in-out; }
  .gallery-item:hover:before {
    background: rgba(0, 0, 0, 0.6);
    bottom: 7px;
    left: 7px;
    right: 7px;
    top: 7px; }

.tagcloud {
  font-size: 0;
  margin: -4px; }
  .tagcloud a {
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    color: #8f8f8f;
    display: inline-block;
    font-size: 12px;
    font-size: 0.85714rem;
    margin: 4px;
    padding: 0.5rem 1.5rem;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out;
    text-transform: uppercase;
    vertical-align: top; }
    .tagcloud a:hover {
      background: transparent;
      border-color: #e17ab4;
      color: #e17ab4; }

/******************************************************************************
  Page 404
*******************************************************************************/
#main.page-404 {
  align-items: center;
  display: flex;
  padding: 4rem 0; }
  @media (max-width: 767px) {
    #main.page-404 {
      padding: 2rem 0; } }
  #main.page-404 .page-content h3 {
    margin-bottom: 4rem; }
    @media (max-width: 767px) {
      #main.page-404 .page-content h3 {
        margin-bottom: 3rem; } }

/******************************************************************************
  Page
*******************************************************************************/
.page .page-content {
  padding: 3rem; }
  @media (max-width: 767px) {
    .page .page-content {
      padding: 1.5rem; } }
