/******************************************************************************
  Fixed Header
*******************************************************************************/
@media #{$min768} {
	.fixed-header {
		.site-header {
			position: fixed;
			z-index: 999;
		}
	}
}