/******************************************************************************
  Social
*******************************************************************************/
.social {
  line-height: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 5px;

    a {
      color: #ccc;
      display: block;
      font-size: 14px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      transition:
        background .2s $animation,
        color .2s $animation;
      width: 24px;

      &:hover {
        background: #e5e5e5;
        color: #666;
      }
    }
  }
}
