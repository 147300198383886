/******************************************************************************
  Sidebar
*******************************************************************************/
.sidebar {
  padding-left: $module-rem * 3;
  @media (max-width: 991px) {
    margin-top: $module-rem * 4;
    padding-left: 1.07145rem !important;
    padding-right: 1.07145rem !important;
  }
  @media #{$max767} {
    margin-bottom: $module-rem * 2;
  }

  .widget {
    margin: 0 auto $module-rem * 4;
    max-width: 325px;
    @media #{$max991} {
      margin-bottom: $module-rem * 2;
    }
  }
}
