/******************************************************************************
  Animation
*******************************************************************************/
.animating {
  opacity: 0;
  transition: opacity .2s $animation;

  &.animated {
    opacity: 1;
  }
}