/******************************************************************************
  Typography
*******************************************************************************/
html {
  font-size: $main-font-size;
  height: 100%;
  @media #{$max767} {
    font-size: $main-font-size-767;
  }
}
body {
  background: #f9f9f9;
  color: $main-text-color;
  font-size: $main-font-size;
  font-size: 1rem;
  font-family: $main-font-family;
  font-weight: $main-font-weight;
  height: 100%;
  line-height: $main-line-height;
  min-width: 300px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  @media #{$max767} {
    line-height: $main-line-height-767;
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: $h1-h6-color;
  font-family: $h1-h6-font-family;
  font-weight: $h1-h6-font-weight;
  line-height: $h1-h6-line-height;
  letter-spacing: $h1-h6-letter-spacing;
  margin: 0 0 #{$h1-h6-line-height / 2}em;
}
h1, .h1 {
  @include fontSize($h1-font-size);
  @media #{$max767} {
    @include fontSize($h1-font-size/1.5);
  }
}
h2, .h2 {
  @include fontSize($h2-font-size);
  @media #{$max767} {
    @include fontSize($h2-font-size/1.4);
  }
}
h3, .h3 {
  @include fontSize($h3-font-size);
  @media #{$max767} {
    @include fontSize($h3-font-size/1.3);
  }
}
h4, .h4 {
  @include fontSize($h4-font-size);
  @media #{$max767} {
    @include fontSize($h4-font-size/1.2);
  }
}
h5, .h5 {
  @include fontSize($h5-font-size);
  @media #{$max767} {
    @include fontSize($h5-font-size/1.1);
  }
}
h6, .h6 {
  @include fontSize($h6-font-size);
}
p,
form {
  margin: 0 0 #{$main-line-height / 2}rem;
  @media #{$max767} {
    margin: 0 0 #{$main-line-height-767 / 2}rem;
  }
}
p:last-child {
  margin-bottom: 0;
}
a {
  color: $main-color;
  text-decoration: none;
  transition: color .2s $animation;

  &:hover,
  &:focus {
    color: rgba($main-color,.7);
    outline: none;
    text-decoration: none;
  }
}
ul,
ol {
  margin: 0 0 #{$main-line-height / 2}rem;
  padding: 0 0 0 $module-rem;
  @media #{$max767} {
    margin: 0 0 #{$main-line-height-767 / 2}rem;
  }

  .no-markers {
    letter-spacing: normal;
    padding-left: 0;

    > li {
      list-style-type: none;
    }
  }
}
img {
  height: auto;
  max-width: 100%;
}
hr {
  border-color: rgba(#000,.15);
  margin-top: #{$main-line-height}rem;
  margin-bottom: #{$main-line-height}rem;
  @media #{$max767} {
    margin-top: #{$main-line-height-767}rem;
    margin-bottom: #{$main-line-height-767}rem;
  }
}
.blockquote {
  border: 0;
  @include fontSize(18px);
  font-style: italic;
  margin-bottom: #{$main-line-height / 2}rem;
  padding: 0;
  quotes: "\201C""\201D";
  @media #{$max767} {
    margin-bottom: #{$main-line-height-767 / 2}rem;
  }

  &:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    line-height: .1em;
    margin-right: .25em;
    vertical-align: -.35em;
  }
}
.table {
  margin-bottom: #{$main-line-height / 2}rem;
  @media #{$max767} {
    margin-bottom: #{$main-line-height-767 / 2}rem;
  }

  thead td,
  th {
    color: #000;
  }
  &.table-bordered,
  &.table-bordered td,
  &.table-bordered th {
    border-color: #ccc;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.figure {
  margin-bottom: #{$main-line-height / 2}rem;
  @media #{$max767} {
    margin-bottom: #{$main-line-height-767 / 2}rem;
  }

  .figure-caption {
    color: #ccc;
    @include fontSize(12px);
    line-height: 1.5;
    text-align: center;
  }
  &.pull-left,
  &.pull-xs-left {
    margin-right: 30px;
  }
  &.pull-sm-left {
    @media #{$min544} {
      margin-right: 30px;
    }
  }
  &.pull-md-left {
    @media #{$min768} {
      margin-right: 30px;
    }
  }
  &.pull-lg-left {
    @media #{$min992} {
      margin-right: 30px;
    }
  }
  &.pull-xl-left {
    @media #{$min1200} {
      margin-right: 30px;
    }
  }
  &.pull-right,
  &.pull-xs-right {
    margin-left: 30px;
  }
  &.pull-sm-right {
    @media #{$min544} {
      margin-left: 30px;
    }
  }
  &.pull-md-right {
    @media #{$min768} {
      margin-left: 30px;
    }
  }
  &.pull-lg-right {
    @media #{$min992} {
      margin-left: 30px;
    }
  }
  &.pull-xl-right {
    @media #{$min1200} {
      margin-left: 30px;
    }
  }
}